/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal, Button, Icon, Grid, Message, Card } from "semantic-ui-react";
import { getTaggedErrors } from "../selectors/errors";
import { getAllActiveCompetitions } from "../actions/competitions";
import { getInvoicePrice } from "../actions/billing";
import { closeAdminAddLicenseModal, openConfirmation } from "../actions/ui";
import {
  addAccessCodesConfirmation,
  addOrgLicenseConfirmation,
} from "../utils/confirmations";
import { ErrorTag } from "../utils/enums";

import OrgAddAccessCodesForm from "./OrgAddAccessCodesForm";
import { addLicense, adminAddOrgCodes } from "../actions/organization";
import AdminAddOrgLicenseForm from "./AdminAddOrgLicenseForm";
import { useActiveComps } from "../queries/get-active-comps";

const AdminAddLicenseModal = ({ open, onOpen, onClose, trigger }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const defaultData = {
    accounts: 0,
    seats: 0,
    competitionId: "ACADEMY",
    couponCode: "",
    discount: 100.0,
  };
  const [formState, setFormState] = React.useState({ ...defaultData });
  //const isAdmin = useSelector((state) => state.user.type === "ADMIN");
  const organization = useSelector((state) =>
    state.organization.list.find(
      (o) => o.id === state.organization.currentlyEditing
    )
  );
  const loading = useSelector((state) => state.organization.addLicenseLoading);
  const competitionsData = useActiveComps();
  const competitions = competitionsData.data;
  const compsLoading = competitionsData.isLoading;

  const errors = useSelector((state) =>
    getTaggedErrors(state.errors, ErrorTag.ADD_ACCESS_CODES)
  );

  const handleClose = () => {
    setFormState({ ...defaultData });
    onClose();
  };

  const handleDone = () => {
    dispatch(
      openConfirmation(
        addOrgLicenseConfirmation(
          organization?.id,
          organization?.name,
          formState,
          () => dispatch(closeAdminAddLicenseModal())
        )
      )
    );
  };

  const renderButton = () => {
    return (
      <Button primary fluid onClick={handleDone} loading={loading}>
        <Icon name="plus" />
        Add
      </Button>
    );
  };

  const renderForm = () => {
    return (
      <AdminAddOrgLicenseForm
        formState={formState}
        setFormState={setFormState}
        errors={[...(errors || [])]}
        loading={loading}
        compOptions={competitions?.filter(c => c.isMembership).map((c) => ({
          key: c.id,
          text: c.name,
          value: c.id,
        }))}
        compsLoading={compsLoading}
        isAdmin
      />
    );
  };

  return (
    <Modal open={open} onOpen={onOpen} onClose={handleClose} trigger={trigger}>
      <Modal.Header>
        <Grid>
          <Grid.Column width={12}>Add License</Grid.Column>
          <Grid.Column width={4}>
            <Button icon="close" onClick={handleClose} floated="right" />
          </Grid.Column>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        {renderForm()}
        {!!errors && (
          <Message
            error
            header="Add License Error"
            content={errors[0].message || "Unknown error"}
          />
        )}
      </Modal.Content>
      <Modal.Actions>{renderButton()}</Modal.Actions>
    </Modal>
  );
};

export default AdminAddLicenseModal;
