import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Container,
  Segment,
  Header,
  Divider,
  Button,
  Form,
  Message,
} from "semantic-ui-react";
import { addToCart, getIndividualLicenseItems } from "../actions/billing";
import { setActiveNavTab } from "../actions/ui";
import { joinOrganization, loadUserData } from "../actions/user";
import { getTaggedErrors } from "../selectors/errors";
import { ErrorTag } from "../utils/enums";
import { getFieldError, getFormError } from "../utils/formErrors";

const JoinOrganization = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const loading = useSelector(
    (state) =>
      state.user.redeemAccessCodeLoading || state.billing.licenseItems.loading
  );
  const errors = useSelector((state) =>
    getTaggedErrors(state.errors, ErrorTag.REDEEM_ACCESS_CODE)
  );
  const user = useSelector((state) => state.user);
  const item = useSelector((state) => state.billing.licenseItems.list[0]);

  useEffect(() => {
    dispatch(getIndividualLicenseItems());
  }, []);

  if (user.organizationId) {
    history.push("/");
  }

  const [formState, setFormState] = React.useState({
    code: "",
  });

  let err;

  return (
    <Segment className="form-background">
      <h2>Are you joining Space Teams as part of a School or Organization?</h2>
      <Container
        style={{
          padding: "2rem 1rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <div>
          <Header>Yes, here is the Access Code I was given:</Header>
          <Form error={!!getFormError(errors)} loading={loading}>
            <Form.Input
              label="Access Code"
              placeholder="Access Code"
              value={formState.code}
              onChange={({ target: { value } }) =>
                setFormState((s) => ({ ...s, code: value }))
              }
              error={
                (err = getFieldError("code", errors)) && {
                  content: err,
                  pointing: "below",
                }
              }
            />
            <Message
              error
              header="Access Code Error"
              content={getFormError(errors)}
            />
          </Form>
          <Button
            primary
            fluid
            size="huge"
            content="Redeem"
            loading={loading}
            onClick={() => dispatch(joinOrganization(formState.code, history))}
          />
        </div>
        <Divider style={{ marginTop: "2rem" }} vertical>
          OR
        </Divider>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flexGrow: 0.5,
          }}
        >
          <Header>I am registering myself</Header>
          <div style={{ height: 60 }}>
          </div>
          <Button
            primary
            fluid
            size="huge"
            content="Checkout"
            loading={loading}
            onClick={() => {
              dispatch(addToCart(item.id));
              dispatch(setActiveNavTab(null));
              history.push("/cart");
            }}
            style={{
              marginTop: "0.7rem",
            }}
          />
        </div>
      </Container>
    </Segment>
  );
};

export default JoinOrganization;
