import {
    SET_USER_COMPETITIONS,
    SET_COMPETITION_BY_ID,
    SET_ACTIVE_COMPETITIONS,
    SET_COMPETITIONS_ACTIVITIES,
    SET_LEADERBOARD,
    SET_SELECTED_LEADERBOARD,
    COMPETITIONS_LOADING,
    LEADERBOARD_LOADING,
    ACTIVITY_DATA_LOADING,
    SET_SCORECARD,
    SET_COMPETITION_TO_CREATE,
    SET_SCORECARD_COMPARISON,
    CLEAR_SCORECARD_COMPARISON,
    SET_SCORECARD_COMPARISON_LOADING,
    SET_CURRENT_COMPETITION,
    SET_ORGANIZATION_MODE,
    UPDATE_COMPETITION_LOADING,
    UPDATE_ACTIVITY_LOADING,
} from "./types";

import moment from "moment";
import { ErrorTag, NavTab, UserType, AlertType } from "../utils/enums";
import { addError, clearErrors } from "./errors";
import compServer from "../api/comp";
import authServer from "../api/auth";
import { getToken } from "./user";
import { setActiveNavTab, openAlert, closeAlert } from "./ui";
import { DateTime } from "luxon";
import { ALERT_TIME } from "../utils/constants";
import { _updateAcademyEvent } from "./organization";

const _getUserCompetitions = async (token) => {
    try {
        const userCompetitions = await compServer.get("/", { headers: { Authorization: token } });
        return userCompetitions.data;
    } catch (err) {
        throw err;
    }
};

const _getCompletedActivities = async (token, teamName, compId) => {
    try {
        const completedActivities = await compServer.get(
            `/activities/completed?team_name=${teamName}&competition_id=${compId}`,
            { headers: { Authorization: token } }
        );
        return completedActivities.data;
    } catch (err) {
        throw err;
    }
};

const _joinCompetition = async (token, compId) => {
    try {
        const body = {
            competitionId: compId,
        };
        await authServer.post("/users/competition", body, { headers: { Authorization: token } });
    } catch (err) {
        throw err;
    }
}

const _getAllActiveCompetitions = async (user) => {
    try {
        const url = user.type === UserType.ADMIN ? "/all" : "/active";
        const activeCompetitionsResults = await compServer.get(url);
        return activeCompetitionsResults.data;
    } catch (err) {
        throw err;
    }
};

export const _getCompetitionById = async (token, id) => {
    try {
        const competitionResults = await compServer.get(`/?id=${id}`, { headers: { Authorization: token } });
        return competitionResults.data;
    } catch (err) {
        throw err;
    }
};

const _getLeaderboard = async (token, compId) => {
    try {
        const leaderboardResult = await compServer.get(
            `/leaderboard?competition_id=${encodeURI(compId)}`,
            { headers: { Authorization: token } }
        );
        return leaderboardResult.data.map(s => ({
            team: { id: s.teamId, name: s.teamName },
            score: s.score,
            league: s.league,
            activityScores: s.activityScores,
        }));
    } catch (err) {
        throw err;
    }
};

const _getActivityLeaderboard = async (token, compId, activityId) => {
    try {
        const compParam = encodeURI(compId);
        const actParam = encodeURI(activityId);
        const leaderboardResult = await compServer.get(
            `/activity-leaderboard?competition_id=${compParam}&activity_id=${actParam}`,
            { headers: { Authorization: token } }
        );
        return leaderboardResult.data.map(s => ({
            team: { id: s.teamId, name: s.teamName },
            score: s.score,
            league: s.league
        }));
    } catch (err) {
        throw err;
    }
};

const _getScorecard = async (token, teamId, activityId) => {
    try {
        const scorecard = await compServer.get(
            `/activities/score?team_id=${teamId}&activity_id=${activityId}`,
            { headers: { Authorization: token } }
        );
        return scorecard.data;
    } catch (err) {
        throw err;
    }
};

const _updateCompetitionDates = async (token, id, startDate, endDate) => {
    try {
        await compServer.patch('/dates', { id, startDate, endDate }, { headers: { Authorization: token } });
    } catch (err) {
        throw err;
    }
}

const _updateCompetitionDownload = async (token, id, enabled) => {
    try {
        await compServer.patch('/download', { id, enabled }, { headers: { Authorization: token } });
    } catch (err) {
        throw err;
    }
}

const _updateCompetitionLearningPlatformEnabled = async (token, id, enabled) => {
    try {
        await compServer.patch('/learning-platform-enabled', { id, enabled }, { headers: { Authorization: token } });
    } catch (err) {
        throw err;
    }
}

const _updateCompetitionTeamModificationsEnabled = async (token, id, enabled) => {
    try {
        await compServer.patch('/team-modifications-enabled', { id, enabled }, { headers: { Authorization: token } });
    } catch (err) {
        throw err;
    }
}

const _updateCompetitionName = async (token, id, name) => {
    try {
        await compServer.patch('/name', { id, name }, { headers: { Authorization: token } });
    } catch (err) {
        throw err;
    }
}

const _updateActivityDates = async (token, id, startDate, endDate) => {
    try {
        await compServer.patch('/activities/dates', { id, startDate, endDate }, { headers: { Authorization: token } });
    } catch (err) {
        throw err;
    }
}

export const setCurrentCompetition = (compId) => async (dispatch, getState) => {
    const current = getState().competitions.currentCompetition;
    try {
        dispatch(clearErrors(ErrorTag.COMPETITIONS));
        if (!compId) {
            dispatch({
                type: SET_CURRENT_COMPETITION,
                payload: null,
            });
            return;
        }
        if (current?.id === compId) {
            console.log('SAME');
            return;
        }
        dispatch({ type: COMPETITIONS_LOADING, payload: true });
        const token = getToken();
        if (!token) {
            return;
        }

        const competition = await _getCompetitionById(token, compId);

        dispatch({
            type: SET_CURRENT_COMPETITION,
            payload: competition,
        });

    } catch (err) {
        err.tag = ErrorTag.COMPETITIONS;
        dispatch(addError(err));
        dispatch(openAlert({
            type: AlertType.ERROR,
            header: "Unable to Load Event",
            message: "Something went wrong when trying to load " +
            "that event, please try again in a few moments",
        }));
        setTimeout(() => dispatch(closeAlert()), ALERT_TIME);
        dispatch({
            type: SET_CURRENT_COMPETITION,
            payload: null,
        });
    } finally {
        dispatch({ type: COMPETITIONS_LOADING, payload: false });
    }
};

export const getUserCompetitions = () => async (dispatch, getState) => {
    try {
        dispatch(clearErrors(ErrorTag.COMPETITIONS));
        dispatch({ type: COMPETITIONS_LOADING, payload: true });
        // TODO(adam): Handle this better
        dispatch({ type: SET_COMPETITIONS_ACTIVITIES, payload: [] });

        const token = getToken();
        if (!token) {
            return;
        }

        const userCompetitions = await _getUserCompetitions(token);
        const teams = getState().teams.list;
        await Promise.all(userCompetitions.map(async (c, i) => {
            const team = teams.find(t => t.id === c.teamId);
            if (team) {
                const completedActivities = await _getCompletedActivities(token, team.name, c.id);
                userCompetitions[i].activities.forEach((a, j) => {
                    const completedActivity = completedActivities.find(ca => ca.activity_id === a.id);
                    if (completedActivity) {
                        userCompetitions[i].activities[j].score = completedActivity.score;
                        userCompetitions[i].activities[j].scorecard = completedActivity.scorecard;
                    }
                });
            }
        }));

        // Set current competition
        const sortedUserComps = userCompetitions.sort((a, b) => {
            const aStart = moment(a.startDate);
            const bStart = moment(a.startDate);
            return aStart.diff(bStart);
        });
        const activeUserComps = sortedUserComps.filter(c => {
            const now = moment();
            const end = moment(c.endDate);
            return end > now;
        });
        if (!getState().competitions.currentCompetition) {
            // If none are active, select most recent
            if (activeUserComps.length === 0) {
                dispatch(setCurrentCompetition(sortedUserComps[0].id));
            } else { // Select newest active comp
                dispatch(setCurrentCompetition(activeUserComps[0].id));
            }
        } else {
            // Update current selected competition
            const comp = getState().competitions.currentCompetition;
            dispatch(setCurrentCompetition(comp.id));
        }

        dispatch({
            type: SET_USER_COMPETITIONS,
            payload: userCompetitions
        });

    } catch (err) {
        err.tag = ErrorTag.COMPETITIONS;
        dispatch(addError(err));
    } finally {
        dispatch({ type: COMPETITIONS_LOADING, payload: false });
    }
};

export const joinCompetition = (compId, history) => async dispatch => {
    try {
        dispatch(clearErrors(ErrorTag.COMPETITIONS));
        dispatch({ type: COMPETITIONS_LOADING, payload: true });

        const token = getToken();
        if (!token) {
            return;
        }

        await _joinCompetition(token, compId);
        const competition = await _getCompetitionById(token, compId);

        dispatch(setActiveNavTab(undefined));
        history.push('/');
        dispatch(setActiveNavTab(NavTab.HOME));
        dispatch(getUserCompetitions(token));

        dispatch(openAlert({
            type: AlertType.SUCCESS,
            header: "Event Joined!",
            message: "You successfully joined the event.",
        }));
        dispatch({
            type: SET_CURRENT_COMPETITION,
            payload: competition,
        });
        setTimeout(() => dispatch(closeAlert()), ALERT_TIME);

    } catch (err) {
        err.tag = ErrorTag.COMPETITIONS;
        dispatch(addError(err));
    } finally {
        dispatch({ type: COMPETITIONS_LOADING, payload: false });
    }
};

export const getAllActiveCompetitions = () => async (dispatch, getState) => {
    try {
        dispatch(clearErrors(ErrorTag.COMPETITIONS));
        dispatch({ type: COMPETITIONS_LOADING, payload: true });

        const activeCompetitions = await _getAllActiveCompetitions(getState().user);
        dispatch({
            type: SET_ACTIVE_COMPETITIONS,
            payload: activeCompetitions
        });

    } catch (err) {
        err.tag = ErrorTag.COMPETITIONS;
        dispatch(addError(err));
    } finally {
        dispatch({ type: COMPETITIONS_LOADING, payload: false });
    }
};

export const getCompetitionById = (id) => async dispatch => {
    try {
        dispatch(clearErrors(ErrorTag.COMPETITIONS));
        dispatch({ type: COMPETITIONS_LOADING, payload: true });

        const token = getToken();
        if (!token) {
            return;
        }

        const competition = await _getCompetitionById(token, id);
        dispatch({
            type: SET_COMPETITION_BY_ID,
            payload: competition
        });

    } catch (err) {
        err.tag = ErrorTag.COMPETITIONS;
        dispatch(addError(err));
    } finally {
        dispatch({ type: COMPETITIONS_LOADING, payload: false });
    }
};

export const getCompetitionLeaderboard = (compId, activityId) => async dispatch => {
    try {
        dispatch(clearErrors(ErrorTag.LEADERBOARD));
        dispatch({ type: LEADERBOARD_LOADING, payload: true });

        const token = getToken();
        if (!token) {
            return;
        }

        let leaderboard;
        if (activityId && activityId !== "Total") {
            leaderboard = await _getActivityLeaderboard(token, compId, activityId);
        } else {
            leaderboard = await _getLeaderboard(token, compId);
        }
        dispatch({
            type: SET_LEADERBOARD,
            payload: leaderboard
        });
    } catch (err) {
        err.tag = ErrorTag.LEADERBOARD;
        dispatch(addError(err));
    } finally {
        dispatch({ type: LEADERBOARD_LOADING, payload: false });
    }
};

export const getScorecard = (teamId, activityId) => async (dispatch, getState) => {
    try {
        dispatch(clearErrors(ErrorTag.SCORECARD));
        dispatch({ type: ACTIVITY_DATA_LOADING, payload: true });

        const token = getToken();
        if (!token) {
            return;
        }

        const scorecard = await _getScorecard(token, teamId, activityId);
        dispatch({
            type: SET_SCORECARD,
            payload: { id: activityId, scorecard }
        });

    } catch (err) {
        err.tag = ErrorTag.SCORECARD;
        dispatch(addError(err));
    } finally {
        dispatch({ type: ACTIVITY_DATA_LOADING, payload: false });
    }
};

export const getScorecardComparison = (myTeamId, theirTeamId, activity) => async (dispatch) => {
    try {
        dispatch(clearErrors(ErrorTag.SCORECARD));
        dispatch({ type: SET_SCORECARD_COMPARISON_LOADING, payload: true });

        const token = getToken();
        if (!token) {
            return;
        }

        const [myScorecard, theirScorecard] = await Promise.all([
            _getScorecard(token, myTeamId, activity.id),
            _getScorecard(token, theirTeamId, activity.id)
        ]);
        dispatch({
            type: SET_SCORECARD_COMPARISON,
            payload: {
                activityName: activity.name,
                myScorecard,
                theirScorecard
            }
        });

    } catch (err) {
        err.tag = ErrorTag.SCORECARD;
        dispatch(addError(err));
    } finally {
        dispatch({ type: SET_SCORECARD_COMPARISON_LOADING, payload: false });
    }
};

export const updateCompetitionDates = (id, startDate, endDate) => async (dispatch) => {
    try {
        dispatch(clearErrors(ErrorTag.UPDATE_COMPETITION));
        dispatch({ type: UPDATE_COMPETITION_LOADING, payload: true });

        const token = getToken();
        if (!token) {
            return;
        }

        await _updateCompetitionDates(token, id, startDate, endDate);

    } catch (err) {
        err.tag = ErrorTag.UPDATE_COMPETITION;
        dispatch(addError(err));
    } finally {
        dispatch({ type: UPDATE_COMPETITION_LOADING, payload: false });
    }
};

export const updateCompetitionDownload = (id, enabled) => async (dispatch) => {
    try {
        dispatch(clearErrors(ErrorTag.UPDATE_COMPETITION));
        dispatch({ type: UPDATE_COMPETITION_LOADING, payload: true });

        const token = getToken();
        if (!token) {
            return;
        }

        await _updateCompetitionDownload(token, id, enabled);

    } catch (err) {
        err.tag = ErrorTag.UPDATE_COMPETITION;
        dispatch(addError(err));
    } finally {
        dispatch({ type: UPDATE_COMPETITION_LOADING, payload: false });
    }
};

const timeExp = /\d{2}:\d{2}(A|P)M/;
const validTime = (time) => timeExp.test(time);
const getDate = (date) => DateTime.fromFormat(date, "yyyy-MM-dd'T'hh:mma").toISO();

export const updateCompetition = (compId, compData, cb) => async (dispatch) => {
    try {
        dispatch(clearErrors(ErrorTag.UPDATE_COMPETITION));
        dispatch({ type: UPDATE_COMPETITION_LOADING, payload: true });

        const token = getToken();
        if (!token) {
            return;
        }

        if (!validTime(compData.startTime)) {
            dispatch(addError({
                tag: ErrorTag.UPDATE_COMPETITION,
                field: "startTime",
                message: "Time must be in format HH:MM(AM|PM)",
            }));
            return;
        }
        if (!validTime(compData.endTime)) {
            dispatch(addError({
                tag: ErrorTag.UPDATE_COMPETITION,
                field: "endTime",
                message: "Time must be in format HH:MM(AM|PM)",
            }));
            return;
        }
        console.log('compData', compData);
        const startDate = DateTime.fromISO(compData.startDate.toISOString()).toFormat("yyyy-MM-dd") + "T" + compData.startTime
        const endDate = DateTime.fromISO(compData.endDate.toISOString()).toFormat("yyyy-MM-dd") + "T" + compData.endTime;
        await _updateCompetitionName(token, compId, compData.name);
        await _updateCompetitionDates(token, compId, getDate(startDate), getDate(endDate));
        await _updateAcademyEvent(undefined, compId, {
            downloadEnabled: compData.downloadEnabled === "enabled",
            teamModificationsEnabled: compData.teamModificationsEnabled === "enabled",
            teamSize: compData.teamSize,
            parentApprovalMethod: compData.parentApprovalMethod,
            scoredActivities: compData.scoredActivities,
            requiredActivities: compData.requiredActivities,
            startDate: compData.startDate,
            endDate: compData.endDate,
        }, token);

        if (cb) {
            cb();
        }

    } catch (err) {
        err.tag = ErrorTag.UPDATE_COMPETITION;
        dispatch(addError(err));
    } finally {
        dispatch({ type: UPDATE_COMPETITION_LOADING, payload: false });
    }
};

export const updateActivityDates = (id, startDate, startTime, endDate, endTime, cb) => async (dispatch) => {
    try {
        dispatch(clearErrors(ErrorTag.UPDATE_ACTIVITY));
        dispatch({ type: UPDATE_ACTIVITY_LOADING, payload: true });

        const token = getToken();
        if (!token) {
            return;
        }

        if (!validTime(startTime)) {
            dispatch(addError({
                tag: ErrorTag.UPDATE_ACTIVITY,
                field: "startTime",
                message: "Time must be in format HH:MM(AM|PM)",
            }));
            return;
        }
        if (!validTime(endTime)) {
            dispatch(addError({
                tag: ErrorTag.UPDATE_ACTIVITY,
                field: "endTime",
                message: "Time must be in format HH:MM(AM|PM)",
            }));
            return;
        }
        const _startDate = DateTime.fromISO(startDate.toISOString()).toFormat("yyyy-MM-dd") + "T" + startTime
        const _endDate = DateTime.fromISO(endDate.toISOString()).toFormat("yyyy-MM-dd") + "T" + endTime;
        await _updateActivityDates(token, id, getDate(_startDate), getDate(_endDate));

        if (cb) {
            cb();
        }

    } catch (err) {
        err.tag = ErrorTag.UPDATE_ACTIVITY;
        dispatch(addError(err));
    } finally {
        dispatch({ type: UPDATE_ACTIVITY_LOADING, payload: false });
    }
};

export const clearScorecardComparison = () => ({
    type: CLEAR_SCORECARD_COMPARISON
});

export const setSelectedLeaderboard = (compName) => ({
    type: SET_SELECTED_LEADERBOARD,
    payload: compName
});

export const setCompetitionToCreate = (comp) => ({
    type: SET_COMPETITION_TO_CREATE,
    payload: comp
});
