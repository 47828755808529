export const ALERT_TIME = 3000;

export const PARENT_CONSENT_AGE = 18;

export const LARGE_LOGO_URL = "https://space-teams-public-resources.s3.us-west-2.amazonaws.com/space-teams-banner.png";

const urlBase = process.env.REACT_APP_URL_BASE;

let authUrl;
if (process.env.NODE_ENV === "development") {
    authUrl = "http://localhost:5000";
} else {
    authUrl = `https://auth.${urlBase}`;
}
export const AUTH_URL = authUrl;

let simsUrl;
if (process.env.NODE_ENV === "development") {
    simsUrl = "http://localhost:5001";
} else {
    simsUrl = `https://sims.${urlBase}`;
}
export const SIMS_URL = simsUrl;

let compUrl;
if (process.env.NODE_ENV === "development") {
    compUrl = "http://localhost:5002";
} else {
    compUrl = `https://competitions.${urlBase}`;
}
export const COMP_URL = compUrl;

let msgUrl;
if (process.env.NODE_ENV === "development") {
    msgUrl = "http://localhost:5003";
} else {
    msgUrl = `https://messages.${urlBase}`;
}
export const MESSAGING_URL = msgUrl;

let resourcesUrl;
if (process.env.NODE_ENV === "development") {
    resourcesUrl = "http://localhost:5004";
} else {
    resourcesUrl = `https://resources.${urlBase}`;
}
export const RESOURCES_URL = resourcesUrl;

let billingUrl;
if (process.env.NODE_ENV === "development") {
    billingUrl = "http://localhost:5005";
} else {
    billingUrl = `https://billing.${urlBase}`;
}
export const BILLING_URL = billingUrl;

export const activityNames = [
    'Planet Builder',
    'Spacecraft Builder',
    'Orbit Designer',
    'Orbital Descent',
    'Habitat Builder',
    'Surface Operations',
];
export const scoredActivityNames = activityNames.filter(n => n !== 'Planet Builder');
export const parentApprovalMethods = ['None', 'Normal', 'Study'];

export const taskNames = [
    'lesson',
    'tutorial',
    'quiz',
    'activity',
];
