import { OrgMode, UserType } from "../utils/enums";

export const getCart = (state) => {
  const ids = state.billing.cart.items.map((item) => item.id);
  const itemList = state.billing.licenseItems.list
  const items = itemList
    .filter((item) => ids.some((id) => item.id === id))
    .map((item) => {
      const country = state.user.country;
      const alternatePrice = item.alternatePrices?.find(
        (ap) => ap.country === country
      );
      item.currency = "USD";
      if (alternatePrice) {
        item.price = alternatePrice.price;
        item.currency = alternatePrice.currency;
      }
      item.qty =
        state.billing.cart.items.find((i) => i.id === item.id)?.qty || 0;
      return item;
    });
  const cart = {
    ...state.billing.cart,
    items,
  };

  return cart;
};

export const getSubtotal = (org) => (state) => {
  if (org?.nasaOrg && state.organization.mode === OrgMode.ACADEMY) {
    return 0.0;
  }
  const cart = getCart(state);
  const subtotal = cart.items.reduce(
    (total, item) => total + Number.parseFloat(item.price) * item.qty,
    0.0
  );
  return subtotal;
};

export const getTotalDiscount = (state) => {
  const cart = getCart(state);
  const appliedDiscounts = cart.discounts;
  const totalDiscount = cart.items.reduce((total, item) => {
    const discount = appliedDiscounts.reduce((amt, d) => {
      const _a = state.organization.mode === OrgMode.ACADEMY ? d.amounts.Academy : d.amounts[item.id];
      if (!_a) {
        console.warn('No discount for', d, 'for event', item.id, 'for item', item);
        return amt;
      }
      const a = Number.parseFloat(_a);
      if (a) {
        return amt + a;
      }
      return amt;
    }, 0.0);
    const amount = discount * Number.parseFloat(item.price) * Number.parseInt(item.qty);
    return total + amount;
  }, 0.0);
  return totalDiscount;
  // return Math.min(totalDiscount, 595);
};
