import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Grid,
    Message,
    Container,
    Card,
    Item,
    Loader,
    Input,
    Button,
    Form,
    Header,
    Dropdown,
    Checkbox,
} from "semantic-ui-react";
import DatePicker from "react-semantic-ui-datepickers";

import {
    updateActivityDates,
    updateCompetition,
    getAllActiveCompetitions,
} from "../actions/competitions";
import { getFieldError, getFormError } from "../utils/formErrors";
import { getTaggedErrors } from "../selectors/errors";
import { ErrorTag } from "../utils/enums";
import { clearErrors } from "../actions/errors";
import { DateTime } from 'luxon';
import { activityNames, scoredActivityNames } from "../utils/constants";
import { useResendAllParentEmails } from "../queries/resend-all-parent-emails";

const Search = ({ search, setSearch, clear }) => {
    return (
        <Grid style={{ marginTop: "1rem" }}>
            <Grid.Row>
                <Grid.Column width={14}>
                    <Input
                        placeholder="Search"
                        fluid
                        value={search}
                        onChange={(e, { value }) => setSearch(value)}
                    />
                </Grid.Column>
                <Grid.Column width={2}>
                    <Button icon="close" onClick={clear} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

const KeyValue = ({ keyName, value }) => {
    return (
        <p>
            <span
                style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                }}
            >
                {`${keyName}: `}
            </span>
            {value}
        </p>
    );
};

const EditCompetitionForm = ({
    formState,
    setFormState,
    updateLoading,
    errors
}) => {
    let err;

    const Activity = ({ activityName }) => {
        return (
            <div style={{ paddingBottom: '1rem' }}>
                <h4>{activityName}</h4>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {scoredActivityNames.some(a => a === activityName) && (<Checkbox
                        key={activityName + '- Scored'}
                        toggle
                        label={'Scored'}
                        checked={formState.scoredActivities[activityName]}
                        onChange={(_, data) => setFormState(s => ({ ...s, scoredActivities: { ...s.scoredActivities, [activityName]: data.checked } }))}
                    />)}
                    <Checkbox
                        key={activityName + '- Required'}
                        toggle
                        label={'Required'}
                        checked={formState.requiredActivities[activityName]}
                        onChange={(_, data) => setFormState(s => ({ ...s, requiredActivities: { ...s.requiredActivities, [activityName]: data.checked } }))}
                    />
                </div>
            </div>
        );
    }

    return (
        <Form error={!!getFormError(errors)}>
            <Form.Input
                label="Competition Name"
                placeholder="Competition Name"
                value={formState.name}
                onChange={({ target: { value } }) => setFormState(s => ({ ...s, name: value }))}
                error={(err = getFieldError("name", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />
            <Form.Field
                error={(err = getFieldError("startDate", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            >
                <label>Start Date</label>
                <DatePicker
                    onChange={
                        (e, data) => setFormState(s => ({ ...s, startDate: data.value }))
                    }
                    value={formState.startDate}
                    showToday={false}
                    format="MM/DD/YYYY"
                />
                {(err = getFieldError("startDate", errors)) && (
                    <Message
                        style={{ display: "block" }}
                        error
                        content={err}
                    />
                )}
            </Form.Field>
            <Form.Input
                label="Start Time"
                placeholder="Start Time"
                value={formState.startTime}
                onChange={({ target: { value } }) => setFormState(s => ({ ...s, startTime: value }))}
                error={(err = getFieldError("startTime", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />
            <Form.Field
                error={(err = getFieldError("endDate", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            >
                <label>End Date</label>
                <DatePicker
                    onChange={
                        (e, data) => setFormState(s => ({ ...s, endDate: data.value }))
                    }
                    value={formState.endDate}
                    showToday={false}
                    format="MM/DD/YYYY"
                />
                {(err = getFieldError("endDate", errors)) && (
                    <Message
                        style={{ display: "block" }}
                        error
                        content={err}
                    />
                )}
            </Form.Field>
            <Form.Input
                label="End Time"
                placeholder="End Time"
                value={formState.endTime}
                onChange={({ target: { value } }) => setFormState(s => ({ ...s, endTime: value }))}
                error={(err = getFieldError("endTime", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />
            <Form.Field>
                <label>Download Enabled</label>
                <Dropdown
                    selection
                    placeholder="Download Enabled"
                    value={formState.downloadEnabled}
                    onChange={(e, { value }) => setFormState(s => ({ ...s, downloadEnabled: value }))}
                    options={[
                        { key: "enabled", text: "Enabled", value: "enabled" },
                        { key: "disabled", text: "Disabled", value: "disabled" },
                    ]}
                    error={(err = getFieldError("downloadEnabled", errors)) && {
                        content: err,
                        pointing: "below"
                    }}
                />
            </Form.Field>
            <Form.Field>
                <label>Team Modifications Enabled</label>
                <Dropdown
                    selection
                    placeholder="Team Modifications Enabled"
                    value={formState.teamModificationsEnabled}
                    onChange={(e, { value }) => setFormState(s => ({ ...s, teamModificationsEnabled: value }))}
                    options={[
                        { key: "enabled", text: "Enabled", value: "enabled" },
                        { key: "disabled", text: "Disabled", value: "disabled" },
                    ]}
                    error={(err = getFieldError("teamModificationsEnabled", errors)) && {
                        content: err,
                        pointing: "below"
                    }}
                />
            </Form.Field>
            <Form.Input
                label="Maximum Team Size"
                placeholder="Maximum Team Size"
                value={formState.teamSize}
                onChange={({ target: { value } }) => setFormState(s => ({ ...s, teamSize: value }))}
                error={(err = getFieldError("teamSize", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />
            <Form.Field>
                <label>Parent Approval Method</label>
                <Dropdown
                    selection
                    placeholder="Parent Approval Method"
                    value={formState.parentApprovalMethod}
                    onChange={(e, { value }) => setFormState(s => ({ ...s, parentApprovalMethod: value }))}
                    options={[
                        { key: "None", text: "None", value: "None" },
                        { key: "Normal", text: "Normal", value: "Normal" },
                    ]}
                    error={(err = getFieldError("parentApprovalMethod", errors)) && {
                        content: err,
                        pointing: "below"
                    }}
                />
            </Form.Field>
            <h3>Activities</h3>
            {activityNames.map(activityName => (
                <Activity activityName={activityName} />
            ))}
        </Form>
    )
};

const EditActivityForm = ({
    name,
    formState,
    setFormState,
    updateLoading,
    errors
}) => {
    let err;
    return (
        <Form error={!!getFormError(errors)}>
            <h3>{name}</h3>
            <Form.Field
                error={(err = getFieldError("startDate", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            >
                <label>Start Date</label>
                <DatePicker
                    onChange={
                        (e, data) => setFormState(s => ({ ...s, startDate: data.value }))
                    }
                    value={formState.startDate}
                    showToday={false}
                    format="MM/DD/YYYY"
                />
                {(err = getFieldError("startDate", errors)) && (
                    <Message
                        style={{ display: "block" }}
                        error
                        content={err}
                    />
                )}
            </Form.Field>
            <Form.Input
                label="Start Time"
                placeholder="Start Time"
                value={formState.startTime}
                onChange={({ target: { value } }) => setFormState(s => ({ ...s, startTime: value }))}
                error={(err = getFieldError("startTime", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />
            <Form.Field
                error={(err = getFieldError("endDate", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            >
                <label>End Date</label>
                <DatePicker
                    onChange={
                        (e, data) => setFormState(s => ({ ...s, endDate: data.value }))
                    }
                    value={formState.endDate}
                    showToday={false}
                    format="MM/DD/YYYY"
                />
                {(err = getFieldError("endDate", errors)) && (
                    <Message
                        style={{ display: "block" }}
                        error
                        content={err}
                    />
                )}
            </Form.Field>
            <Form.Input
                label="End Time"
                placeholder="End Time"
                value={formState.endTime}
                onChange={({ target: { value } }) => setFormState(s => ({ ...s, endTime: value }))}
                error={(err = getFieldError("endTime", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />
        </Form>
    )
};

const ActivityItem = ({
    activity,
    updateActivity,
    updateLoading,
    isEditing,
    handleEditClick,
    handleCancelEditing,
    formState,
    setFormState,
    errors,
}) => {
    const renderEditButtons = () => {
        return (
            <React.Fragment>
                <Button
                    content={isEditing ? "Save" : "Edit"}
                    primary={isEditing}
                    loading={isEditing && updateLoading}
                    onClick={isEditing ? updateActivity : handleEditClick}
                />
                {isEditing && (
                    <Button
                        content="Cancel"
                        onClick={handleCancelEditing}
                    />
                )}
            </React.Fragment>
        );
    };
    if (isEditing) {
        return (
            <Item key={activity.name} >
                <Item.Content>
                    <Item.Description>
                        <EditActivityForm
                            formState={formState}
                            setFormState={setFormState}
                            updateLoading={updateLoading}
                            errors={errors}
                        />
                    </Item.Description>
                    <Item.Extra>
                        {renderEditButtons()}
                        {errors && errors.length > 0 && errors.map(e => (
                            <Message error>{e.message}</Message>
                        ))}
                    </Item.Extra>
                </Item.Content>
            </Item >
        );
    }
    const dateFormat = "LLL dd, yyyy 'at' h:mm a";
    return (
        <React.Fragment>
            <Item key={activity.id} >
                <Item.Content>
                    <Item.Description>
                        <KeyValue keyName="Name" value={activity.name} />
                        <KeyValue keyName="Start Date" value={DateTime.fromISO(activity.startDate).toFormat(dateFormat)} />
                        <KeyValue keyName="End Date" value={DateTime.fromISO(activity.endDate).toFormat(dateFormat)} />
                    </Item.Description>
                </Item.Content>
                <Item.Extra>
                    {renderEditButtons()}
                </Item.Extra>
            </Item>
        </React.Fragment>
    )
};

const CompetitionItemContent = ({
    competition,
    updateLoading,
    isEditing,
    isEditingActivity,
    handleEditClick,
    handleEditActivityClick,
    handleCancelEditing,
    handleCancelEditingActivity,
    formState,
    setFormState,
    activityFormState,
    setActivityFormState,
    updateActivity,
    errors,
}) => {
    const resendAllParentEmails = useResendAllParentEmails();

    const renderEditButtons = () => {
        return (
            <React.Fragment>
                <Button
                    content={isEditing ? "Save" : "Edit"}
                    primary={isEditing}
                    loading={isEditing && updateLoading}
                    onClick={handleEditClick}
                />
                {isEditing && (
                    <Button
                        content="Cancel"
                        onClick={handleCancelEditing}
                    />
                )}
            </React.Fragment>
        );
    };
    if (isEditing) {
        return (
            <Item key={competition.name} >
                <Item.Content>
                    <Item.Description>
                        <EditCompetitionForm
                            formState={formState}
                            setFormState={setFormState}
                            updateLoading={updateLoading}
                            errors={errors}
                        />
                    </Item.Description>
                    <Item.Extra>
                        {renderEditButtons()}
                        {errors && errors.length > 0 && errors.map((e, i) => (
                            <Message key={i} error>{e.message}</Message>
                        ))}
                    </Item.Extra>
                </Item.Content>
            </Item >
        );
    }
    const dateFormat = "LLL dd yyyy hh:mm a";
    return (
        <React.Fragment>
            <Item key={competition.id} >
                <Item.Content>
                    <Item.Description>
                        <KeyValue keyName="Name" value={competition.name} />
                        <KeyValue keyName="ID" value={competition.id} />
                        <KeyValue keyName="Start Date" value={DateTime.fromISO(competition.startDate).toFormat(dateFormat)} />
                        <KeyValue keyName="End Date" value={DateTime.fromISO(competition.endDate).toFormat(dateFormat)} />
                        <KeyValue keyName="Download Enabled" value={competition.settings.downloadEnabled ? "Enabled" : "Disabled"} />
                        <KeyValue keyName="Team Modifications Enabled" value={competition.settings.teamModificationsEnabled ? "Enabled" : "Disabled"} />
                        <KeyValue keyName="Maximum Team Size" value={competition.settings.teamSize} />
                        {activityNames.map(a => (
                            <KeyValue key={a} keyName={a + " Required"} value={competition.settings.requireActivities[a] ? "Yes" : "No"} />
                        ))}
                        {scoredActivityNames.map(a => (
                            <KeyValue key={a} keyName={a + " Scored"} value={competition.settings.scoreActivities[a] ? "Yes" : "No"} />
                        ))}
                        <KeyValue keyName="Parent Approval Method" value={competition.settings.parentApprovalMethod} />
                    </Item.Description>
                </Item.Content>
                <Item.Extra>
                    {renderEditButtons()}
                    <Button
                        onClick={() => resendAllParentEmails.mutate(competition.id)}
                        disabled={resendAllParentEmails.isLoading}
                    >
                        Resend Parent Consent Emails
                    </Button>
                </Item.Extra>
            </Item>
            {competition.activities.length > 0 && (
                <div style={{
                    marginBottom: "1rem",
                }}>
                    <Header size="tiny">Activities</Header>
                    <Item.Group divided style={{
                        marginLeft: "2.2rem",
                        marginTop: "-1rem",
                    }}>
                        {competition.activities.map(a => (
                            <ActivityItem
                                key={a.id}
                                activity={a}
                                updateActivity={updateActivity(a.id)}
                                updateLoading={updateLoading}
                                isEditing={!!isEditingActivity[a.id]}
                                handleEditClick={() => handleEditActivityClick(a)}
                                handleCancelEditing={handleCancelEditingActivity(a.id)}
                                formState={activityFormState}
                                setFormState={setActivityFormState}
                                errors={errors}
                            />
                        ))}
                    </Item.Group>
                </div>
            )}
        </React.Fragment>
    )
};

const CompetitionItem = ({ competition }) => {
    const updateLoading = useSelector((state) => state.competitions.updateLoading);
    const errors = useSelector((state) => [
        ...(getTaggedErrors(state.errors, ErrorTag.UPDATE_COMPETITION) || []),
        ...(getTaggedErrors(state.errors, ErrorTag.UPDATE_ACTIVITY) || []),
    ]);
    const dispatch = useDispatch();

    const [isEditing, setIsEditing] = React.useState(false);
    const editingActivities = competition.activities
        ? competition.activities.reduce((acc, curr) => ({ ...acc, [curr.id]: false }), {})
        : {};
    const [isEditingActivity, setIsEditingActivity] = React.useState(editingActivities);
    const initialFormState = {
        name: competition.name || "",
        startDate: new Date(competition.startDate) || null,
        startTime: DateTime.fromISO(competition.startDate).toFormat("hh:mma"),
        endDate: new Date(competition.endDate) || null,
        endTime: DateTime.fromISO(competition.endDate).toFormat("hh:mma"),
        downloadEnabled: competition.settings.downloadEnabled ? "enabled" : "disabled",
        teamModificationsEnabled: competition.settings.teamModificationsEnabled ? "enabled" : "disabled",
        teamSize: competition.settings.teamSize,
        scoredActivities: competition.settings.scoreActivities,
        requiredActivities: competition.settings.requireActivities,
        parentApprovalMethod: competition.settings.parentApprovalMethod,
    };
    const initialActivityFormState = {
        activityId: "",
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
    };
    const [formState, setFormState] = React.useState({ ...initialFormState });
    const [activityFormState, setActivityFormState] = React.useState({ ...initialActivityFormState });
    const [editingActivity, setEditingActivity] = React.useState({});

    const handleEditButtonClick = () => {
        if (isEditing) {
            dispatch(updateCompetition(competition.id, formState, () => {
                setIsEditing(false);
                dispatch(getAllActiveCompetitions());
                setFormState({ ...initialFormState });
            }));
        } else {
            setIsEditing(true);
        }
    }

    const handleCancelEditing = () => {
        setIsEditing(false);
        setFormState({ ...initialFormState });
        dispatch(clearErrors(ErrorTag.UPDATE_COMPETITION));
        dispatch(clearErrors(ErrorTag.UPDATE_ACTIVITY));
    };

    const handleEditActivityClick = (activity) => {
        setActivityFormState(s => ({
            ...s,
            startDate: new Date(activity.startDate) || null,
            startTime: DateTime.fromISO(activity.startDate).toFormat("hh:mma"),
            endDate: new Date(activity.endDate) || null,
            endTime: DateTime.fromISO(activity.endDate).toFormat("hh:mma"),
        }));
        setIsEditingActivity(s => ({ ...s, [activity.id]: true }));
        setEditingActivity(activity);
    };

    const updateActivity = (id) => () => {
        dispatch(updateActivityDates(id, activityFormState.startDate, activityFormState.startTime, activityFormState.endDate, activityFormState.endTime, () => {
            dispatch(getAllActiveCompetitions());
            setActivityFormState({ ...initialActivityFormState });
            setIsEditingActivity(s => ({ ...s, [id]: false }));
        }));
    };

    const handleCancelEditingActivity = (id) => () => {
        setActivityFormState({ ...initialActivityFormState });
        setEditingActivity({});
        setIsEditingActivity(s => ({ ...s, [id]: false }));
        dispatch(clearErrors(ErrorTag.UPDATE_ACTIVITY));
    };

    return (
        <CompetitionItemContent
            competition={competition}
            updateCompetition={updateCompetition}
            updateLoading={updateLoading}
            isEditing={isEditing}
            isEditingActivity={isEditingActivity}
            handleEditClick={handleEditButtonClick}
            handleEditActivityClick={handleEditActivityClick}
            handleCancelEditing={handleCancelEditing}
            handleCancelEditingActivity={handleCancelEditingActivity}
            formState={formState}
            setFormState={setFormState}
            activityFormState={activityFormState}
            setActivityFormState={setActivityFormState}
            updateActivity={updateActivity}
            errors={errors}
        />
    );

};

const CompetitionList = ({ competitions, search }) => {
    const [compList, setCompList] = React.useState(competitions);
    const [isFiltered, setIsFiltered] = React.useState(false);
    React.useEffect(() => {
        if (search !== "") {
            const regex = new RegExp(search, "i");
            setCompList(compList.filter(c => regex.test(c.name)));
            setIsFiltered(true);
        } else if (isFiltered && search === "") {
            setCompList(competitions);
            setIsFiltered(false);
        }
    }, [search]);

    return (
        <Item.Group divided>
            {compList
                .map(c => (
                    <CompetitionItem
                        key={c.id}
                        competition={c}
                    />
                ))}
        </Item.Group>
    );
};

const AdminCompetitionManagement = () => {

    const dispatch = useDispatch();

    const loading = useSelector((state) =>
        state.competitions.loading || !state.competitions.activeComps
    );
    const competitions = useSelector((state) =>
        state.competitions.activeComps?.sort((a, b) => a.name > b.name ? 1 : -1).filter(c => !c.isMembership).map(c => {
            c.settings.requireActivities = activityNames.reduce((acc, curr) => ({ ...acc, [curr]: c.settings.requireActivities[curr] ?? false }), {});
            c.settings.scoreActivities = scoredActivityNames.reduce((acc, curr) => ({ ...acc, [curr]: c.settings.scoreActivities[curr] ?? false }), {});
            return c;
        })
    );

    const [hasLoaded, setHasLoaded] = React.useState(false);
    const [search, setSearch] = React.useState("");

    const clearSearch = () => {
        setSearch("");
    };

    // Loading
    React.useEffect(() => {
        if (!loading && !hasLoaded) {
            dispatch(getAllActiveCompetitions());
            setHasLoaded(true);
        }
    }, [loading, hasLoaded]);

    const renderCompList = () => {
        if (loading) {
            return <Loader />;
        }
        return (
            <CompetitionList
                competitions={competitions}
                search={search}
            />
        );
    };

    return (
        <Container>
            <Card
                className="dashboard-card"
                centered
            >
                <Card.Content>
                    <Grid style={{ marginTop: "1rem" }}>
                        <Grid.Row>
                            <Grid.Column width={10}>
                                <h3>Competition Management</h3>
                            </Grid.Column>
                            <Grid.Column width={2}>
                                <Button
                                    icon="refresh"
                                    onClick={() => dispatch(getAllActiveCompetitions())}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Card.Content>
                <Card.Content>
                    <h4>Search</h4>
                    <Search
                        search={search}
                        setSearch={setSearch}
                        clear={clearSearch}
                    />
                </Card.Content>
                <Card.Content>
                    {renderCompList()}
                </Card.Content>
            </Card>
        </Container>
    );
};

export default AdminCompetitionManagement;
