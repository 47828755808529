import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Form,
    Dropdown
} from "semantic-ui-react";
import { setCurrentCompetition } from "../actions/competitions";
import { setOrganizationMode } from "../actions/organization";
import { useOrganization } from "../queries/get-organization";
import { OrgMode, UserType } from "../utils/enums";
import { uniqueById } from "../utils/unique";

const formStyle = {
    margin: "1rem auto",
    fontSize: "1.3rem",
    paddingLeft: "1rem",
};
const labelStyle = {
    color: "white",
    marginRight: "1rem"
};
const fieldStyle = {
    textAlign: "center",
    margin: 0,
};

const Loading = () => {
    return (
        <p>Loading Events...</p>
    );
};

const CompetitionSelection = () => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const comp = useSelector((state) => state.competitions.currentCompetition);
    const compLoading = useSelector((state) => state.competitions.loading);
    const organization = useOrganization(user.organizationId);
    const org = organization.data;
    const orgMode = useSelector((state) => state.organization.mode);
    const orgLoading = organization.isLoading;
    const userComps = useSelector(
        (state) => uniqueById(state.competitions.userComps.filter(c => !c.teamId || c.teamIsActive))
    );
    const activeComps = useSelector((state) => state.competitions.activeComps);
    let compsList = user.type === UserType.USER ? userComps : activeComps;

    useEffect(() => {
        if (compLoading) {
            return;
        }
        if (user.type !== UserType.ORG_MANAGER) {
            return;
        }
        if (activeComps.length === 0) {
            return;
        }

        // let compsList = [...activeComps.filter(c => c.isMembership), ...org?.license?.events ?? []];
        if (!comp) {
            const available = compsList.filter(c => (orgMode === OrgMode.ACADEMY ? !c.isMembership : c.isMembership));
            // console.log('list', compsList);
            // console.log('available', orgMode, available);
            if (available.length > 0) {
                dispatch(setCurrentCompetition(available[0].id));
            }
            return;
        }
        if (comp && (orgMode === OrgMode.ACADEMY && comp.isMembership) || (orgMode !== OrgMode.ACADEMY && !comp.isMembership)) {
            const available = compsList.filter(c => orgMode === OrgMode.ACADEMY ? !c.isMembership : c.isMembership);
            if (available.length > 0) {
                dispatch(setCurrentCompetition(available[0].id));
            } else {
                // if (comp !== null) {
                //     dispatch(setCurrentCompetition(null));
                // }
            }
        }
    }, [orgMode, comp, activeComps, userComps, org, compLoading]);

    if (user.type === UserType.ORG_MANAGER && (orgLoading || !org)) {
        return (
            <Loading />
        );
    }

    if (user.type === UserType.ORG_MANAGER) {
        compsList = org?.license ? org.license.events : [];
        const selfPayEvents = org?.competitionLicenses.flatMap(l => l.events) ?? [];
        for (const event of selfPayEvents) {
            if (!compsList.map(c => c.id).includes(event.id)) {
                compsList.push(event);
            }
        }
    }

    if (!compsList) {
        return (
            <Loading />
        );
    }
    if (compsList.length === 0 && user.type !== UserType.ORG_MANAGER) {
        return <div />;
    }
    // // FIXME: Temp hide for academy changes
    // if (user.type !== UserType.ADMIN) {
    //     return <></>;
    // }
    const formatOrgMode = (mode) => {
        return mode.slice(0, 1) + mode.slice(1).toLowerCase();
    }

    const OrgModeSelection = () => {
        return (
            <Form.Field inline style={fieldStyle}>
                <label style={labelStyle}>Mode: </label>
                <Dropdown name="org-mode" text={formatOrgMode(orgMode)}>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            key={OrgMode.ACADEMY}
                            text={formatOrgMode(OrgMode.ACADEMY)}
                            onClick={() => dispatch(setOrganizationMode(OrgMode.ACADEMY))}
                        />
                        <Dropdown.Item
                            key={OrgMode.COMPETITION}
                            text={formatOrgMode(OrgMode.COMPETITION)}
                            onClick={() => dispatch(setOrganizationMode(OrgMode.COMPETITION))}
                        />
                    </Dropdown.Menu>
                </Dropdown>
            </Form.Field>
        );
    }

    const competitionOptions = compsList
        .filter(c => {
            return orgMode === OrgMode.ACADEMY
                ? (!c.isMembership)
                : (c.isMembership);
        })
        .map(c => ({ key: c.id, text: c.name, value: c.id }));

    return (
        <Form style={formStyle} id="competition-selection">
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                maxWidth: 500,
                margin: "0 auto",
            }}>
                {user.type === UserType.ORG_MANAGER && <OrgModeSelection />}
                <Form.Field inline style={fieldStyle}>
                    <label style={labelStyle}>Selected Event: </label>
                    <Dropdown
                        name="competition"
                        text={comp ? comp.name : 'Select an Event'}
                        value={comp?.id ?? 'Select an Event'}
                        scrolling
                        search
                        options={competitionOptions}
                        onChange={(e, opt) => {
                            dispatch(setCurrentCompetition(opt.value));
                        }}
                    />
                </Form.Field>
            </div>
        </Form>
    );
};

export default CompetitionSelection;
