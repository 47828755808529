import {
    SET_ORGANIZATION_LOADING,
    SET_CREATE_ORGANIZATION_LOADING,
    SET_ORGANIZATION_DATA,
    SET_ORGANIZATION_LIST,
    SET_ACCESS_CODES_LOADING,
    SET_ADD_ACCESS_CODES_LOADING,
    SET_ADD_ORG_MANAGER_LOADING,
    SET_ACCESS_CODES,
    LOGOUT,
    SET_ORGANIZATION_UPDATE_LOADING,
    SET_CURRENTLY_EDITING_ORGANIZATION,
    SET_LICENSE_INFO_LOADING,
    SET_ADD_LICENSE_LOADING,
    SET_ACADEMY_STUDENTS,
    SET_LICENSE_INFO,
    SET_CREATE_EVENT_LOADING,
    ADD_EVENT,
    ACADEMY_EVENTS_LOADING,
    SET_ACADEMY_EVENTS,
    STUDENT_UPLOAD_LOADING,
    UPDATE_EVENT_LOADING,
    UPDATE_EVENT,
    SET_ORGANIZATION_MODE,
} from "../actions/types";
import { OrgMode } from "../utils/enums";

const initialState = {
    loading: false,
    createOrgLoading: false,
    updateOrgLoading: false,
    org: null,
    mode: OrgMode.ACADEMY,
    list: [],
    accessCodes: [],
    accessCodesLoading: false,
    addAccessCodesLoading: false,
    addManagerLoading: false,
    currentlyEditing: null,
    licenseInfo: {
        loading: false,
        seats: 0,
        accounts: 0,
    },
    addLicenseLoading: false,
    academyStudents: [],
    academyEvents: {
        loading: false,
        list: []
    },
    createEventLoading: false,
    updateEventLoading: false,
    studentUploadLoading: false,
};

const organization = (state = initialState, action) => {
    switch (action.type) {
        case SET_ORGANIZATION_LOADING: {
            return { ...state, loading: action.payload };
        }
        case SET_CREATE_ORGANIZATION_LOADING: {
            return { ...state, createOrgLoading: action.payload };
        }
        case SET_ORGANIZATION_UPDATE_LOADING: {
            return { ...state, updateOrgLoading: action.payload };
        }
        case SET_ORGANIZATION_DATA: {
            return { ...state, org: action.payload };
        }
        case SET_ORGANIZATION_LIST: {
            return { ...state, list: action.payload };
        }
        case SET_ORGANIZATION_MODE: {
            return { ...state, mode: action.payload };
        }
        case SET_ACCESS_CODES: {
            return { ...state, accessCodes: action.payload };
        }
        case SET_ACCESS_CODES_LOADING: {
            return { ...state, accessCodesLoading: action.payload };
        }
        case SET_ADD_ACCESS_CODES_LOADING: {
            return { ...state, addAccessCodesLoading: action.payload };
        }
        case SET_ADD_ORG_MANAGER_LOADING: {
            return { ...state, addManagerLoading: action.payload };
        }
        case SET_CURRENTLY_EDITING_ORGANIZATION: {
            return { ...state, currentlyEditing: action.payload };
        }
        case SET_LICENSE_INFO_LOADING: {
            return {
                ...state,
                licenseInfo: { ...state.licenseInfo, loading: action.payload },
            }
        }
        case SET_ADD_LICENSE_LOADING: {
            return {
                ...state,
                addLicenseLoading: action.payload,
            }
        }
        case SET_ACADEMY_STUDENTS: {
            return {
                ...state,
                academyStudents: action.payload,
            }
        }
        case SET_LICENSE_INFO: {
            return {
                ...state,
                licenseInfo: { ...action.payload, loading: false },
            }
        }
        case SET_CREATE_EVENT_LOADING: {
            return {
                ...state,
                createEventLoading: action.payload,
            }
        }
        case UPDATE_EVENT_LOADING: {
            return {
                ...state,
                updateEventLoading: action.payload,
            }
        }
        case UPDATE_EVENT: {
            return {
                ...state,
                org: {
                    ...state.org,
                    license: {
                        ...state.org?.license,
                        events: state.org?.license?.events?.map(event => {
                            if (event.id === action.payload.id) {
                                return action.payload;
                            }
                            return event;
                        }),
                    },
                },
            };
        }
        case ADD_EVENT: {
            return {
                ...state,
                academyEvents: {
                    loading: state.academyEvents.loading,
                    list: [ ...state.academyEvents.list, action.payload ],
                },
            }
        }
        case ACADEMY_EVENTS_LOADING: {
            return {
                ...state,
                academyEvents: {
                    ...state.academyEvents,
                    loading: action.payload,
                }
            }
        }
        case SET_ACADEMY_EVENTS: {
            return {
                ...state,
                academyEvents: {
                    ...state.academyEvents,
                    list: action.payload,
                }
            }
        }
        case STUDENT_UPLOAD_LOADING: {
            return {
                ...state,
                studentUploadLoading: action.payload,
            }
        }
        case LOGOUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default organization;
