/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { CSVLink } from "react-csv";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, Button } from "semantic-ui-react";
import { getOrgData } from "../actions/organization";
import { useOrganization } from "../queries/get-organization";
import { OrgMode } from "../utils/enums";
import { generateCSVData } from "../utils/student-csv";

const keyStyle = {
  fontWeight: "bold",
  fontSize: "1.1rem",
};
const valueStyle = {
  fontWeight: "light",
  fontSize: "1.1rem",
  float: "right",
};

const OrgAcademyLicense = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state) => state.user);
  const { loggedIn, organizationId } = user;
  const organization = useOrganization(organizationId);
  const academyLicense = organization.data?.license;
  const competitionLicenses = organization.data?.competitionLicenses;
  const studentData = organization.data?.studentData;
  const loading = organization.isLoading;
  const orgMode = useSelector((state) => state.organization.mode);
  const hasLicense = !loading && ((orgMode === OrgMode.ACADEMY && academyLicense) || (orgMode === OrgMode.COMPETITION && competitionLicenses?.length > 0));

  // TODO(adam): Dropdown select
  const license = orgMode === OrgMode.ACADEMY ? academyLicense : (competitionLicenses ? competitionLicenses[0] : undefined);

  const KeyValue = ({ keyName, value }) => (
    <p>
      <span style={keyStyle}>{`${keyName}: `}</span>
      <span style={valueStyle}>{value}</span>
    </p>
  );

  const handlePurchaseClick = () => {
    history.push("/organization-store");
  };

  const renderContent = () => {
    if (loading) {
      return "Loading...";
    }
    if (!hasLicense || !license) {
      return (
        <div>
          <h4>No License Purchased</h4>
        </div>
      );
    }
    const data = generateCSVData(license.students, studentData);
    return (
      <React.Fragment>
        <KeyValue keyName="Total Accounts" value={license.students.length} />
        <KeyValue
          keyName="Accounts Redeemed"
          value={license.students.filter((s) => s.redeemed).length}
        />
        {orgMode === OrgMode.ACADEMY && (<KeyValue keyName="Total Seats" value={license.numSeats} />)}
        <CSVLink data={data} style={{ fontSize: "1.2rem" }} disabled={loading}>
          Download Codes
        </CSVLink>
      </React.Fragment>
    );
  };

  return (
    <Card
      className={`dashboard-card ${loading ? "dashboard-card--loading" : ""}`}
      centered
    >
      <Card.Content>
        <Card.Header>{orgMode === OrgMode.ACADEMY ? "Academy License" : "Competition License"}</Card.Header>
      </Card.Content>
      <Card.Content>{renderContent()}</Card.Content>
      <Card.Content extra>
        {hasLicense && (
          <Button primary onClick={() => history.push("/student-upload")}>
            Upload Students
          </Button>
        )}
        <Button primary onClick={handlePurchaseClick}>
          {hasLicense ? "Manage Licenses" : "Purchase License"}
        </Button>
      </Card.Content>
    </Card>
  );
};

export default OrgAcademyLicense;
